import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { addStudent } from './scripts/purchases';
import { GradeRecord } from './Students';
import './styles/AddUser.css';

type props = {
    bussy: boolean,
    setBussy: React.Dispatch<React.SetStateAction<boolean>>,
    hide: () => void,
    gradeList: GradeRecord[] | null,
    setGradeList: React.Dispatch<React.SetStateAction<GradeRecord[] | null>>
}

const AddUser = React.forwardRef((props: props, ref: React.Ref<HTMLDivElement>) => {
    const { courseid } = useParams();
    const { hide, bussy, setBussy, gradeList, setGradeList } = props;
    const [email, setEmail] = useState("");

    const addUser = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (courseid && !bussy) {
                setBussy(true);
                const newRecord = await addStudent("purchases", courseid, email);
                if (gradeList) setGradeList(gradeList.concat(newRecord));
                setEmail("");
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }
    const addUserButton = async () => {
        try {
            if (courseid && !bussy) {
                setBussy(true);
                const newRecord = await addStudent("purchases", courseid, email);
                if (gradeList) setGradeList(gradeList.concat(newRecord));
                setEmail("");
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }
    return (
        <div ref={ref} className='addUser-information-item'>
            <div className='addUser-information-background' onClick={hide} />
            <div className='addUser-information-card'>
                <img className='close-window' src={process.env.PUBLIC_URL + '/img/x-icon.png'} onClick={hide}></img>
                <form onSubmit={addUser}>
                    <label>Correo electrónico:</label><br />
                    <input style={style} className="vc-input" type="text" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                </form>
                <div>
                    <button className='addUser-button' type='submit' onClick={addUserButton}>Agregar</button>
                </div>
            </div>
        </div>
    );
});

const style = {
    height: "35px",
    width: "230px"
}

export default AddUser;