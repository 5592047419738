import './styles/main.css';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { getCourses, postCourse, Course, CourseSummary } from './common/scripts/courses';
import { getUserData, User } from './common/scripts/users';
import { getPurchases } from './common/scripts/purchases'
import LoadingScreen from './common/LoadingScreen';
import UpperBar from './common/UpperBar';
import './styles/main.css';
import './styles/courses.css';
import './common/styles/CourseDescription.css'
import 'react-tabs/style/react-tabs.css'
import { getProfessors, deleteProfessor, Professor, createProfessor } from './common/scripts/professors';
import ProfessorCard from './common/ProfessorCard';
import ProfessorInfo from './common/ProfessorInfo';
import LogMain from './common/LogMain';
import LogInfo from './common/LogInfo';
import { useAuth } from '../context/AuthProvider';
import { clearServerCache } from './common/scripts/cache';
import { useTranslation } from 'react-i18next';
import * as Firestore from "firebase/firestore"


type props = {
    logOut: () => Promise<void>
}
type ClickHandler = (courseid: string) => (e: React.MouseEvent) => void;

export default function Main(props: props) {
    const [professors, setProfessorsData] = useState<Professor[]>([]);
    const [selectedProfessor, setSelectedProfessor] = useState<Professor>();
    const professorInfo = useRef<HTMLDivElement>(null);
    const logInfo = useRef<HTMLDivElement>(null);
    const scrollElement = useRef<HTMLDivElement>(null);
    const arrowRight = useRef<HTMLDivElement>(null);
    const arrowLeft = useRef<HTMLImageElement>(null);
    const cardCourse = useRef<HTMLDivElement>(null);
    let [leftArrowStyle, setLeftArrowStyle] = useState({ display: "none" });
    let [rightArrowStyle, setRightArrowStyle] = useState({ display: "flex" });
    const [courses, setCoursesData] = useState<CourseSummary[]>([]);
    const [purchases, setPurchases] = useState<string[]>([]);
    const [bussy, setBussy] = useState(true);
    const [done, setDone] = useState(false);
    const [show, setShow] = useState({ display: "none" });
    const { loading, user, admin } = useAuth();
    const { t } = useTranslation();
    let table = CreateTable(8, 11);
    let navigate = useNavigate();
    const clickHandler: ClickHandler = (courseid) => (e) => {
        navigate('/courses/' + courseid);
    };
    let activeCourses = courses.filter(course => course.enabled);
    let publishedCourses = activeCourses.filter(course => course.published);
    let ownedCourses = !admin ? activeCourses.filter(course => purchases.includes(course.id)) : courses;

    useEffect(() => {
        const fetch = async () => {
            downloadProfessors();
            if (user) setPurchases(await getPurchases(user.uid));
            const coursesData = await getCourses();
            setCoursesData(coursesData);
            setDone(true);
            setBussy(false);
        }
        if (courses.length === 0) fetch();

                   
            if (cardCourse.current) 
            {            
                cardCourse.current.style.display = "none" 
            }
        

    }, [courses, navigate])

    const scrollToRight = () => {
        scrollElement.current!.scrollLeft += window.screen.width / 3;
    }

    const scrollToLeft = () => {
        scrollElement.current!.scrollLeft -= window.screen.width / 3;
    }

    const diseableButton = () => {
        if (scrollElement.current) {
            const max = (scrollElement.current.scrollWidth - 20) - scrollElement.current.clientWidth;
            scrollElement.current.scrollLeft >= max
                ? (arrowRight.current ? setRightArrowStyle({ display: "none" }) : console.log("no display"))
                : (arrowRight.current ? setRightArrowStyle({ display: "flex" }) : console.log("no display"));
            scrollElement.current.scrollLeft <= 20
                ? (arrowLeft.current ? setLeftArrowStyle({ display: "none" }) : console.log("no display"))
                : (arrowLeft.current ? setLeftArrowStyle({ display: "flex" }) : console.log("no display"));
        }
    }

    const downloadProfessors = async () => {
        setProfessorsData(await getProfessors());
    }

    const showProfessor = (professor: Professor) => {
        setSelectedProfessor(professor);
        professorInfo.current!.style.display = "flex";
    }

    const addProfessor = async () => {
        try {
            if (!bussy) {
                setBussy(true);
                let newProfessor: Professor = { id: "", name: "nuevo profesor", special: "especialidad", sinapsis: "", thumbnail: "" };
                newProfessor.id = await createProfessor(newProfessor);
                setProfessorsData(professors.concat(newProfessor));
                setBussy(false);
            }
        }
        catch (e) {
            setBussy(false);
            console.log(e);
        }
    }

    const removeProfessor = async (professor: Professor) => {
        try {
            if (!bussy && window.confirm('¿Esta seguro que desea borrar el registro para el profesor <' + professor.name + '>?')) {
                setBussy(true);
                await deleteProfessor(professor.id);
                setProfessorsData(professors.filter(professor_ => professor_.id !== professor.id));
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo eliminar profesor");
        }
    }

    const addCourse = async () => {
        try {
            if (!bussy) {
                setBussy(true);
                console.log("subiendo curso");
                const newCourse: Course = {
                    description: "",
                    thumbnail: "",
                    videoThumbnail: "",
                    video: "",
                    signupForm: "",
                    title: "nuevo curso",
                    subtitle: "",
                    professors: [],
                    published: false,
                    price: 0,
                    currency: 0,
                    time: 0,
                    teachersCount: 0,
                    studentCount: 0,
                    start_date: Firestore.Timestamp.now(),
                    end_date: Firestore.Timestamp.now(),
                    levels: [{ title: "nuevo modulo", visible: true, cases: [{ title: "nuevo caso", spawn: 0 }] }],
                    descriptionBlocks: []
                };
                setCoursesData(courses.concat({
                    id: await postCourse(newCourse),
                    thumbnail: newCourse.thumbnail,
                    videoThumbnail: newCourse.videoThumbnail,
                    start_date: newCourse.start_date,
                    end_date: newCourse.end_date,
                    studentCount: newCourse.studentCount,
                    time: newCourse.time,
                    title: newCourse.title,
                    published: newCourse.published
                }));
                setBussy(false);
            }
        }
        catch
        {
            setBussy(false);
            console.log("no se pudo subir el curso");
        }
    }

    const refresh = async () => {
        await clearServerCache(user?.uid!);
        setCoursesData([]);
    }

    return (
        <>

            {!done ? (<LoadingScreen />) : (
                <>
                    <div className='area'>
                        <div className='welcomeArea'>
                            <div className='lastcourse'>
                                <h1>{t("Bienvenido a la ")}<span>{t("medicina fetal")}</span></h1>
                                <div className='videoThumbnail'>
                                    {publishedCourses.length > 0 && (publishedCourses[1].videoThumbnail ? (publishedCourses[1].videoThumbnail.endsWith(".mp4") && (
                                        <video width="100%" height="auto" autoPlay muted loop>
                                            <source src={publishedCourses[1].videoThumbnail} type="video/mp4"></source>
                                        </video>
                                    )) : (publishedCourses[0].thumbnail && (
                                        <img src={publishedCourses[1].thumbnail} width="100%" height="100%" alt="Thumbnail">
                                        </img>
                                    )))}
                                    {publishedCourses.length > 0 && (
                                        <>
                                            <h2>{publishedCourses[1].title}</h2>
                                            <img src={process.env.PUBLIC_URL + '/img/last_course_play_icon.png'} className='playButton' onClick={clickHandler(publishedCourses[1].id)} alt="Play" />
                                        </>
                                    )}
                                </div>
                            </div>
                            {/*ownedCourses.length > 0 &&
                                <div className='lastcourses'>
                                    <div className='vertical-grid-container'>
                                        <h2>{t("My courses")}</h2>
                                        {ownedCourses.slice(0, 3).map((course, index) =>
                                            <div className='course' key={"owned-" + index} onClick={clickHandler(course.id)}>
                                                <img className='img-course' src={process.env.PUBLIC_URL + course.thumbnail || process.env.PUBLIC_URL + '/img/no-image.jpeg'} alt="Thumbnail"></img>
                                                <div className='textContainer'>{course.title}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>*/
                            }
                            <div style={{ "flex": "1 2", "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                                <div style={{ "height": "330px", "maxWidth": "600px", "flex": "1 2", "borderRadius": "15px", "minWidth": "600px", "position": "relative" }}>
                                    <img style={{ "width": "100%", "height": "100%", "borderRadius": "15px", "border": 0 }} src='https://media.virtualcampusmfm.com/Thumbnails/Courses/DEMO.PNG' />
                                    <img
                                        src={process.env.PUBLIC_URL + '/img/last_course_play_icon.png'}
                                        style={{ "position": "absolute", "bottom": "30px", "right": "30px", "width": "70px", "cursor": "pointer" }}
                                        onClick={() => navigate("/play/demo/demo/demo")}
                                        alt="PlayD" />
                                    <div style={{ "position": "absolute", "top": "30px", "left": "30px", "width": "300px", "color": "white", "fontSize": "24px", "fontFamily": "Manrope", "fontWeight": 700 }}>
                                        {t("Explora nuestro campus")}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Tabs defaultIndex={0} className="main-tabs">
                            <TabList>
                                <Tab className='tab' key='main'><span color="#003C89">{t("Courses")}</span></Tab>
                                <Tab className='tab' key='mycourses'><span color="#003C89">{t("My courses")}</span></Tab>
                                {user && admin && (
                                    <>
                                        <Tab className='tab' key='table'><span color="#003C89">{t("Table")}</span></Tab>
                                        <Tab className='tab' key='table'><span color="#003C89">{t("Professors")}</span></Tab>
                                        <Tab className='tab' key='table'><span color="#003C89">{t("Logs")}</span></Tab>
                                    </>
                                )}
                            </TabList>
                            <TabPanel className="grid-main-tabs" key="main-panel">
                                <div className='main-tabs-title'>{t("New courses!")}</div>

                                <div style={leftArrowStyle} className='left-arrow' ref={arrowLeft} onClick={scrollToLeft}>
                                    <img id="left-arrow" src={process.env.PUBLIC_URL + '/img/arrow-left.png'} alt="Arrow"></img>
                                </div>
                                <div style={rightArrowStyle} className='right-arrow' ref={arrowRight} onClick={scrollToRight}>
                                    <img id="right-arrow" src={process.env.PUBLIC_URL + '/img/arrow-right.png'} alt="Arrow"></img>
                                </div>

                                <div className='horizontal-grid-container' ref={scrollElement} onScroll={diseableButton}>
                                    {publishedCourses.map((course, index) =>
                                        <>
                                            <div className='courseContainer' key={"course-" + index} ref={cardCourse} >
                                                <img className='courseImage' src={process.env.PUBLIC_URL + course.thumbnail} alt="Thumbnail"></img>
                                                <div className='courseTextTitle'>
                                                    <div>{course.title}</div>
                                                </div>
                                                <div className='students'>
                                                    <div>{course.studentCount}{t("Students")}</div>
                                                    <div>{course.id}</div>
                                                </div>
                                                <div className='hours'>
                                                    <div>{course.time} Hrs</div>
                                                </div>
                                                <div className='buttonCourse' onClick={clickHandler(course.id)}>
                                                    <div>{admin ? t("MANAGE") : t("INFORMATION")}</div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </TabPanel>
                            <TabPanel key="courses-panel" className="flex-column gap15">
                                {admin && <div className='blue-button-a' style={{ "maxWidth": "200px", "height": "50px" }} onClick={refresh}>{t("Recharge")}</div>}
                                <div className="mycourses">
                                    {ownedCourses.map((course, index) =>
                                        <div className="myCourseContainer" key={"my-" + index}>
                                            <div className="text-mycourses-container">
                                                <div>{course.title}</div>
                                            </div>
                                            <div className="button-mycourses-container" onClick={clickHandler(course.id)}>
                                                <div>
                                                    {admin ? t("Manage") : t("Go to course")}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {admin && (
                                        <div className="add-myCourseContainer" onClick={addCourse}>
                                            <img id="right-arrow" src={process.env.PUBLIC_URL + '/img/add_icon.png'} alt="Arrow"></img>
                                            <div>{t("Add course")}</div>
                                        </div>
                                    )}
                                </div>
                            </TabPanel>
                            {user && admin && (
                                <TabPanel key="table-panel">
                                    <div className="grid-table-container">
                                        {table.map((column, i) =>
                                            column.map((row, j) => <input key={i + "-" + j}></input>)
                                        )}
                                    </div>
                                </TabPanel>
                            )}
                            {user && admin && (
                                <TabPanel className="professors-tab" key="professors-panel">
                                    {professors && professors.map((professor, index) =>
                                        <ProfessorCard key={"professor-" + index} professor={professor} showProfessor={showProfessor} removeProfessor={removeProfessor} />
                                    )}
                                    <div className='add-button add-professor-item' onClick={addProfessor}>
                                        <div className='add-professor-icon'></div>
                                        <div className='add-professor-text'>
                                            <div>{t("Add doctor")}</div>
                                        </div>
                                    </div>
                                </TabPanel>
                            )}
                            {user && admin && (
                                <TabPanel className="log-tab" key="log">
                                    <LogMain showRecord={() => logInfo.current!.style.display = "flex"} />
                                </TabPanel>
                            )}
                        </Tabs>
                        <div className='copyright'>
                            <div className='content-copyright'>
                                <img style={{ "width": "150px" }} src={process.env.PUBLIC_URL + '/img/mfm-copyright.png'} alt="Professor"></img>
                                <div>Copyright 2021</div>
                                <div>Medicina Fetal México</div>
                                <div>{t("Terms and privacy")}</div>
                            </div>
                        </div>
                    </div>
                    <UpperBar logOut={props.logOut} navigate={navigate} />
                    <ProfessorInfo
                        professor={selectedProfessor}
                        hide={() => professorInfo.current!.style.display = "none"}
                        ref={professorInfo}
                        professors={professors}
                        setProfessorsData={setProfessorsData}></ProfessorInfo>
                    <LogInfo ref={logInfo} hide={() => logInfo.current!.style.display = "none"} ></LogInfo>
                </>
            )}
        </>
    );
}

function CreateTable(x: number, y: number) {
    let table: string[][] = [];
    for (var i = 0; i < x; i++) {
        table[i] = [];
        for (var j = 0; j < y; j++)
            table[i][j] = i + "," + j;
    }
    return table;
}