import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { blockTitleOptions, Course } from "./scripts/courses";
import { setCourseDescription, setCourseTextBlocksField } from "./scripts/fields";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid"

interface props {
    course: Course,
    setCourse: React.Dispatch<React.SetStateAction<Course | null>>,
    bussy: boolean,
    setBussy: React.Dispatch<React.SetStateAction<boolean>>
}

function CourseInfo(props: props) {
    const blockRefs = useRef<Array<HTMLDivElement | null>>([])
    const descriptionRef = useRef<HTMLDivElement | null>(null);
    const { admin } = useAuth();
    const { courseid } = useParams();
    const { course, setCourse, bussy, setBussy } = props;
    const { t } = useTranslation();

    useEffect(() => {
        if (descriptionRef.current) {
            descriptionRef.current.dataset.replicatedValue = course.description;
        }
        for(let i in blockRefs.current) {
            if (course.descriptionBlocks![i] && blockRefs.current[i]) blockRefs.current[i]!.dataset.replicatedValue = course.descriptionBlocks![i].text;
        }
    }, [course.description, course.descriptionBlocks])

    const setDescription = (courseid: string) => (e: React.FocusEvent) => {
        if (admin) setCourseDescription(courseid, e.target.textContent || "");
    }

    const removeDescriptionBlock = async (targetIndex: number) => {
        try {
            if (courseid && !bussy) {
                setBussy(true);
                let newArr = [...course.descriptionBlocks!].filter((_, index) => index !== targetIndex);
                await setCourseTextBlocksField(courseid, newArr);
                setCourse({...course, descriptionBlocks: newArr});
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }

    const addDescriptionBlock = async () => {
        try {
            if (courseid && !bussy) {
                setBussy(true);
                let newArr = [...course.descriptionBlocks!];
                newArr.push({id: 0, text: ""});
                await setCourseTextBlocksField(courseid, newArr);
                setCourse({...course, descriptionBlocks: newArr});
                setBussy(false);
            }
        } catch (error) {
            setBussy(false);
            console.log(error);
        }
    }
    
    return (
        <>
            <div key={"description-title"} className='block-title-select'>{course?.title}</div>
            <div className='grow-wrap' ref={descriptionRef}>
                <textarea className='textarea'
                    readOnly={!admin}
                    value={course.description}
                    onChange={(e) => { setCourse({...course, description: e.target.value}) }}
                    onBlur={setDescription(courseid || "")} />
            </div>
            {course.descriptionBlocks?.map((block, index) => (
                <div key={`block-${block.id}-${index}`}>
                    {admin? 
                        <div className='block-title-container'>
                            <select value={block.id} className='block-title-select' onChange={(e) => { 
                                    if (courseid)
                                    {
                                        let newArr = [...course.descriptionBlocks!];
                                        newArr[index].id = e.target.selectedIndex;
                                        setCourse({...course, descriptionBlocks: newArr})
                                        setCourseTextBlocksField(courseid, newArr);
                                    }
                                }}>
                                {blockTitleOptions.map((opt, o_index) => (
                                    <option value={o_index} key={"b" + "-" + index + "-" + o_index}>{opt}</option>
                                ))}
                            </select>
                            <img 
                                style={{"cursor": "pointer"}}
                                src={process.env.PUBLIC_URL + '/img/delete.png'} 
                                width="30px" height="30px" 
                                onClick={() => removeDescriptionBlock(index)}></img>
                        </div>
                            :
                        <div className='block-title-select'>{blockTitleOptions[block.id]}</div>
                    }
                    <div className='grow-wrap' ref={el => blockRefs.current[index] = el}>
                        <textarea className='textarea'
                            readOnly={!admin}
                            value={block.text}
                            onChange={(e) => { 
                                let newArr = [...course.descriptionBlocks!];
                                newArr[index].text = e.target.value;
                                setCourse({...course, descriptionBlocks: newArr})
                            }}
                            onBlur={() => {
                                if (admin && courseid) {
                                    setCourseTextBlocksField(courseid, course.descriptionBlocks!);
                                }
                            }} />
                    </div>
                </div>
            ))}
            {course && admin && (
                <div className='add-button add-module' onClick={addDescriptionBlock}>
                    <img src={process.env.PUBLIC_URL + '/img/add_icon.png'} alt="add" width="36.67px" height="36.67px"></img>
                    <div className='add-module-items'>{t("Add subcategory")}</div>
                </div>
            )}
        </>
    );
}

export default CourseInfo;