import './styles/ProfessorInfo.css';
import React, { useEffect, useState } from 'react';
import { Professor } from './scripts/professors';
import { User } from './scripts/users';
import { setProfessorField } from './scripts/professors';
import { propTypes } from 'react-bootstrap/esm/Image';
import { useAuth } from '../../context/AuthProvider';
import { uploadImage } from './scripts/files';

type props = {
    professor: Professor | undefined,
    hide: () => void,
    professors?: Professor[],
    setProfessorsData?: React.Dispatch<React.SetStateAction<Professor[]>>
}

const setField = (props: props, field: string) => (e: React.FocusEvent<HTMLDivElement, Element>) => {
    if (props.professor) {
        setProfessorField(props.professor.id, field, e.target.innerText);
        if (props.setProfessorsData && props.professors) {
            let newProfessors = [...props.professors];
            let newProfessor = props.professor;
            switch (field) {
                case "name":
                    newProfessor.name = e.target.innerText;
                    console.log("name changed");
                    break;
                case "special":
                    newProfessor.special = e.target.innerText;
                    break;
                case "sinapsis":
                    newProfessor.sinapsis = e.target.innerText;
                    break;
            }
            for (var i in newProfessors) {
                if (newProfessors[i].id === newProfessor.id) {
                    newProfessors[i] = newProfessor;
                    console.log("changed");
                }
            }
            props.setProfessorsData(newProfessors);
        }
    }
}

const saveSinopsis = (props: props) => (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (props.professor) {
        setProfessorField(props.professor.id, "sinapsis", e.target.value);
        if (props.setProfessorsData && props.professors) {
            let newProfessors = [...props.professors];
            let newProfessor = props.professor;
            newProfessor.sinapsis = e.target.value;
            for (var i in newProfessors) {
                if (newProfessors[i].id === newProfessor.id) {
                    newProfessors[i] = newProfessor;
                    console.log("changed");
                }
            }
            props.setProfessorsData(newProfessors);
        }
    }
}

const ProfessorInfo = React.forwardRef((props: props, ref: React.Ref<HTMLDivElement>) => { 
    const [sinopsis, setSinopsis] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    const {admin} = useAuth();

    useEffect(() => {
        if (props.professor) setSinopsis(props.professor.sinapsis);
    }, [props.professor?.sinapsis])

    useEffect(() => {
        if (props.professor) setThumbnail(props.professor.thumbnail);
    }, [props.professor?.thumbnail])

    const setPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = await uploadImage(e.target.files![0], "Thumbnails/Professors", props.professor?.id!);
        setProfessorField(props.professor!.id, "thumbnail", url);
        setThumbnail(url);
        props.setProfessorsData!(professors => {
            let newProfessors = [...professors];
            for (var i in newProfessors) {
                if (newProfessors[i].id === props.professor?.id) {
                    newProfessors[i] = {...props.professor!, thumbnail: url};
                    console.log("changed");
                }
            }
            return newProfessors;
        });
    }

    return(
        <div ref={ref} className='professor-information-item'>
            <div className='professor-information-background' onClick={props.hide} />
            <div className='professor-information-card'>
                <img className='close-window' src={process.env.PUBLIC_URL + '/img/x-icon.png'} onClick={props.hide}></img>
                <div className='professor-img-card'>
                    <img className='professor-img-card-img' src={thumbnail || process.env.PUBLIC_URL + '/img/none-profile-photo.png'} alt="Professor"></img>
                    {admin && (
                        <>
                            <input type={"file"} id="file" accept="image/png, image/jpeg" onChange={setPhoto}/>
                            <label style={{"width": "38px"}} className='upload-button' htmlFor="file">
                                <img src={process.env.PUBLIC_URL + "/img/add_icon.png"}/>
                            </label>
                        </>
                    )}
                </div>
                <div className='professor-text-card'>
                    <div className='professor-name' contentEditable={admin} suppressContentEditableWarning onBlur={setField(props, "name")}>
                        {props.professor?.name}
                    </div>
                    <div className='professor-special' contentEditable={admin} suppressContentEditableWarning onBlur={setField(props, "special")}>
                        {props.professor?.special}
                    </div>
                    <textarea 
                        className='professor-info' 
                        value={sinopsis} 
                        readOnly={!admin} 
                        onChange={(e) => setSinopsis(e.target.value)}
                        onBlur={saveSinopsis(props)}
                    />
                </div>
            </div>
        </div>
)});


export default ProfessorInfo;