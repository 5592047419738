import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CountrySelect, { countryArr } from "../geographic/CountrySelect";
import LoadingScreen from "./LoadingScreen";
import { Course } from "./scripts/courses";
import { setCourseHQs } from "./scripts/fields";
import axios from "axios";

interface props {
    course: Course,
    setCourse: React.Dispatch<React.SetStateAction<Course | null>>,
    bussy: boolean,
    setBussy: React.Dispatch<React.SetStateAction<boolean>>
}

function CourseHQs(props: props) {
    const { course, setCourse, bussy, setBussy } = props;
    const { t } = useTranslation();
    const { courseid } = useParams<string>();

    const addModule = async () => {
        try {
            setBussy(true);
            const newHQs = [...course.headquarters!, {entity: 6, country: "MX", attendances: []}];
            setCourse({...course, headquarters: newHQs});
            await setCourseHQs(courseid!, newHQs);
            setBussy(false);
        } catch (error) {
            console.log(error);
        }
    }

    const removeHQ = (courseId:string, hqIndex:number) =>{
        console.log(`$id:${courseId}, index: ${hqIndex}`);        
        let endpoint = "https://api.virtualcampusmfm.com";
        axios.put(`${endpoint}/api/removehdoncourses/${courseId}/${hqIndex}`)
        .then(()=>{window.location.reload()})
        .catch((error)=>{console.error(error)})
    }

    const hqInputHandle = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name} = e.target;
        let newHQs = course.headquarters;
        newHQs![index] = {...newHQs![index], [name]: value}
        setCourse({...course!, headquarters: newHQs})
    }

    const hqInputBlur = async () => {
        await setCourseHQs(courseid!, course.headquarters || []);
    }

    useEffect(() => {
        if (!course.headquarters) setCourse({...course, headquarters: []});
        console.log("courseID:" + courseid);
        
    }, [])

    if (bussy) return <div>Cargando...</div>

    return (
        <div>
            {course.headquarters?.map((headquater, index) => (
                <div key={`hq-1-${index}`} className="flex flex-col mb-5">
                    <div className='text-3xl font-manrope font-bold text-blue-900'>
                        {t("Sede") 
                        + " - " 
                        + (headquater.name || (headquater.country === "MX" ? mxEntities[headquater.entity] : countryArr.find(country => country.value === headquater.country)?.label))}
                    </div>
                    <div className="flex bg-blue-50 px-5 py-5 gap-5 flex-wrap">
                        <div className="flex flex-col">
                            <label className="text-sm">{t("Pais")}</label>
                            <CountrySelect value={headquater.country} onChange={e => {
                                let newHQs = course.headquarters;
                                newHQs![index] = {...newHQs![index], country: e.target.value}
                                setCourse({...course!, headquarters: newHQs})
                                setCourseHQs(courseid!, course.headquarters || [])
                            }}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm">{t("Estado")}</label>
                            <select value={headquater.entity} className="bg-white-50 w-64" disabled={headquater.country !== "MX"} onChange={e => {
                                let newHQs = course.headquarters;
                                newHQs![index] = {...newHQs![index], entity: parseInt(e.target.value)}
                                setCourse({...course!, headquarters: newHQs})
                                setCourseHQs(courseid!, course.headquarters || [])
                            }}>
                                {mxEntities.map((entity, index) => <option value={index} key={`entity-${index}`}>{entity}</option>)}
                            </select>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm">{t("Titúlo")}</label>
                            <input name="name" value={headquater.name || ""} onChange={hqInputHandle(index)} onBlur={hqInputBlur}/>
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm">{t("Ubicación")}</label>
                            <input name="location" value={headquater.location || ""} onChange={hqInputHandle(index)} onBlur={hqInputBlur}/>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/img/delete.png'} width="30px" height="30px" onClick = { ()=>{removeHQ(courseid ?? "", index)} }></img>
                    </div>
                </div>
            ))}
            <div className="add-button add-module" onClick={addModule}>
                <img src={process.env.PUBLIC_URL + '/img/add_icon.png'} alt="add" width="36.67px" height="36.67px"/>
                <div className='add-module-items'>{t("Añadir sede")}</div>
            </div>
        </div>
    );
}

const mxEntities = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas"
]

export default CourseHQs;