import { useParams } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react'
import { addProfessorOnCourse, Professor } from "./scripts/professors";
import { Course } from "./scripts/courses";
import { useOverlay } from "../../context/OverlayProvider";

type props = {
    bussy: boolean,
    setBussy: React.Dispatch<React.SetStateAction<boolean>>,
    professors: Professor[],
    addProfessorLocal: (id: string) => void
}

function ProfessorAdd(props: props) {
    const { courseid } = useParams();
    const [ overlay, setOverlay ] = useOverlay();
    const selectedIndex = overlay.formAux.selectedIndex;
    const { bussy, setBussy, addProfessorLocal, professors } = props;

    const addProfessor = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!bussy && courseid) {
                setBussy(true);
                await addProfessorOnCourse(courseid, professors[selectedIndex].id);
                addProfessorLocal(professors[selectedIndex].id);
                setBussy(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    console.log(selectedIndex);

    return (
        <form onSubmit={addProfessor} className='flex-column gap15' style={{"padding": "15px", "marginTop": "30px"}}>
            <select value={selectedIndex} onChange={(e) => setOverlay(overlay => { return {...overlay, formAux: { ...overlay.formAux, selectedIndex: e.target.selectedIndex }}})}>
                {professors.map((professor, value) => (
                    <option key={professor.id} value={value}>{professor.name}</option>
                ))}
            </select>
            <button className="blue-button-a">Añadir</button>
        </form>
    );
}

export default ProfessorAdd;