import axios from "axios";
import { apiUri } from "./endpoints";

export const uploadImage = async (file: File, folder: string, filename: string) : Promise<string> => {
    const data = new FormData();
    data.append("file", file);
    data.append("filename", filename);
    data.append("folder", folder);
    const response = await axios.post(apiUri + "/media", data);
    return response.data.url;
}