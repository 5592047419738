import { useTable, Column } from "react-table";

type props = {
    columns: readonly Column<{}>[],
    data: readonly {}[],
    showRecord: () => void
}

export default function LogTable(props: props) {
  const { columns, data, showRecord } = props;
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });
  return (
    <table className="log-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className="log-table-header log-table-item" id={column.render("Header")?.toString()} {...column.getHeaderProps()}>
                {column.render("Header")?.toString() !== "info"? column.render("Header") : ""}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr className="log-table-row" {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td className="log-table-cell log-table-item" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}