import { useTable, Column } from "react-table";
import { TableType } from "./Students";
import './styles/StudentsTable.css'

type props = {
  columns: readonly Column<{}>[],
  data: readonly {}[],
  acceptUser: (email: string) => Promise<void>,
  removeUser: (email: string) => Promise<void>,
  type: TableType
}

export default function Table(props: props) {
  const { columns, data, removeUser, acceptUser } = props;
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });
  return (
    <table id="students-grade-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {index === 1 ? (<th className="student-table-header student-table-item"></th>) : <th className="student-table-header student-table-item" id="info"></th>}
            {headerGroup.headers.map(column => (
              <th className="student-table-header student-table-item" id={column.render("Header")?.toString()} {...column.getHeaderProps()}>
                {column.render("Header")?.toString() !== "info" ? column.render("Header") : ""}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
                <td className="student-table-cell">
                  <div>
                  { props.type !== TableType.Students && <img onClick={() => acceptUser(row.cells[1].value)} style={{ cursor: "pointer", width: "29px" }} src={process.env.PUBLIC_URL + "/img/correct-icon.png"} />}
                  <img onClick={ () => { removeUser(row.cells[1].value); } } style={{ cursor: "pointer" }} src={process.env.PUBLIC_URL + "/img/cancel-circle.png"} />
                  </div>
                </td>
              {row.cells.map(cell => {
                return <td className="student-table-cell student-table-item" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}