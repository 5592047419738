import { ChangeEvent } from "react";
import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"
import esLocale from "i18n-iso-countries/langs/es.json"

interface props {
    value: string,
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

countries.registerLocale(enLocale);
countries.registerLocale(esLocale);
const contryObj = countries.getNames("es", { select: "official"});
export const countryArr = Object.entries(contryObj).map(([key, value]) => {
    return {
        label: value,
        value: key
    };
})

function CountrySelect(props: props) {
    const { value, onChange } = props;

    return (
        <select className="bg-white-50 w-64" value={value} name="country" id="country" onChange={onChange}>
            {countryArr.map((country, index) => <option key={`country-index-${index}`} value={country.value} label={country.label}>{country.label}</option>)}
        </select>
    );
}

export default CountrySelect;