import './styles/Design.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Course } from './scripts/courses';
import { setCourseField } from './scripts/fields';
import { uploadImage } from './scripts/files';

type props = {
    course: Course | null,
    setCourse: React.Dispatch<React.SetStateAction<Course | null>>
}

function Design(props: props) {
    const { courseid } = useParams();
    const { course, setCourse } = props;
    const [loading, setLoading] = useState(false);

    const setFile = (path: string) => async (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        const url = await uploadImage(e.target.files![0], path, courseid!);
        await setCourseField(courseid!, e.target.name, url)
        setCourse!(course => { return {...course!, [e.target.name]: url}})
        setLoading(false)
    }

    if (loading) return <div style={{"width": "100%", "display": "flex", "justifyContent": "center"}}><img width="64px" height="64px" src={process.env.PUBLIC_URL + "/img/Rolling-1s-200px.svg"}/></div>

    return (
        <div className='design-area'>
            <div className='design-input-area'>
                <div className='design-input-combo' style={{"position": "relative"}}>
                    <label>Portada: </label>
                    <input name="thumbnail" className='upload-button' type={"file"} id="thumbnail" accept="image/png, image/jpeg" onChange={setFile("Thumbnails/Courses")}/>
                    <label style={{"width": "120px", "height": "40px"}} className='blue-button-a' htmlFor="thumbnail">
                        Subir archivo
                    </label>
                </div>
                <img src={(`${course?.thumbnail}?date=${Date.now()}`) || process.env.PUBLIC_URL + '/img/no-image.jpeg'} alt="Thumbnail"/>
            </div>
            <div className='design-input-area'>
                <div className='design-input-combo' style={{"position": "relative"}}>
                    <label>Banner: </label>
                    <input name="banner" className='upload-button' type={"file"} id="banner" accept="image/png, image/jpeg" onChange={setFile("Placards/Courses")}/>
                    <label style={{"width": "120px", "height": "40px"}} className='blue-button-a' htmlFor="banner">
                        Subir archivo
                    </label>
                </div>
                <img src={(`${course?.banner}?date=${Date.now()}`) || process.env.PUBLIC_URL + '/img/no-image.jpeg'} alt="Banner"/>
            </div>
            <div className='design-input-area'>
                <div className='design-input-combo' style={{"position": "relative"}}>
                    <label>Trailer: </label>
                    <input name="video" className='upload-button' type={"file"} id="video" accept="video/mp4" onChange={setFile("Videos/Trailers/Courses")}/>
                    <label style={{"width": "120px", "height": "40px"}} className='blue-button-a' htmlFor="video">
                        Subir archivo
                    </label>
                </div>
                <video key={Date.now()} width="193" height="105">
                    <source src={`${course?.video}?date=${Date.now()}`} type="video/mp4" />
                </video>
            </div>
            <div className='design-input-area'>
                <div className='design-input-combo' style={{"position": "relative"}}>
                    <label>Video Portada: </label>
                    <input name="videoThumbnail" className='upload-button' type={"file"} id="videoThumbnail" accept="video/mp4" onChange={setFile("Videos/Thumbnails/Courses")}/>
                    <label style={{"width": "120px", "height": "40px"}} className='blue-button-a' htmlFor="videoThumbnail">
                        Subir archivo
                    </label>
                </div>
                <video key={Date.now()} width="193" height="105">
                    <source src={`${course?.videoThumbnail}?date=${Date.now()}`} type="video/mp4" />
                </video>
            </div>
            <div className='design-input-area'>
                <div className='design-input-combo'>
                    <label>Formulario: </label>
                    <input 
                        placeholder='https://www.ejemplo.com/form' 
                        className="vc-input" 
                        value={course?.signupForm} 
                        onBlur={(e) => {setCourseField(courseid || "", "signupForm", e.target.value); if(course) course.signupForm = e.target.value}}
                        onChange={(e)=> setCourse(course => { return {...course!, signupForm: e.target.value}})}/>
                </div>
                <div className='design-form-button-area'>
                    <button className='design-form-button' onClick={() => window.open(course?.signupForm, '_blank')}>Probar</button>
                </div>
            </div>
            <div className='design-input-area'>
                <div className='design-input-combo' style={{"position": "relative"}}>
                    <label>Programa: </label>
                    <input name="programUrl" className='upload-button' type={"file"} id="file" accept="application/pdf" onChange={setFile("Documents/Courses/Programs")}/>
                    <label style={{"width": "120px", "height": "40px"}} className='blue-button-a' htmlFor="file">
                        Subir archivo
                    </label>
                </div>
            </div>
        </div>
    );
}

export default Design;