import React, { RefObject, useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import '../../App.css'
import { useAuth } from '../../context/AuthProvider'
import { useTranslation } from 'react-i18next';

type props = {
    title: string,
    type: string
    emailRef: RefObject<HTMLInputElement>
    passwordRef: RefObject<HTMLInputElement>
    passwordVRef: RefObject<HTMLInputElement>
    event: () => Promise<void>
}

interface CustomizedState {
    from?: {pathname: string}
}

export default function Form(props: props) {
    const { title, type, emailRef, passwordRef, passwordVRef, event } = props;
    let navigate = useNavigate();
    const { loading, user } = useAuth();
    const location = useLocation();
    const state = location.state as CustomizedState;
    const { t } = useTranslation();
    let from = state?.from?.pathname || '/';

    console.log(state);

    if (loading) return <></>

    if (user) return <Navigate to={from} replace/>

    return (
        <>
            <div className='form-background' />
            <form onSubmit={(e) => {e.preventDefault(); event();}} className='form-body'>
                <img style={{"height": "80px"}} className='logo' src={process.env.PUBLIC_URL + '/img/mfm-blue.png'} alt="MFM Blue" /><br />
                <div className='form-container'>
                    <div className='form'>
                        <div className='header'>
                            <h1>{title}</h1>
                            {false && <div className='tour'>
                                <img className='tour-logo' src={process.env.PUBLIC_URL + '/img/tour.png'} alt="tour" width="20px"/><br />
                                <div className='tour-title' onClick={() => navigate("/play/")}>{t("Explore campus")}</div>
                            </div>}
                        </div><br />
                        <label htmlFor="email">{t("E-mail")}</label>
                        <input className='vc-input' style={inputStyle} id="email" ref={emailRef} /><br />
                        {(type !== "recovery" &&
                            <>
                                <label htmlFor="password">{t("Password")}</label>
                                <input className='vc-input' style={inputStyle} id="password" type="password" ref={passwordRef} /><br />
                            </>
                        )}
                        {type === "recovery" && (
                            <div className='footer'>
                                <br />
                                <button>{t("Send e-mail")}</button><br /><br />
                                <a onClick={() => navigate('/login')}>{t("Back to login")}</a><br /><br />
                            </div>
                        )}
                        {type === "login" && (
                            <div className='footer'>
                                <br />
                                <button>{t("Login")}</button><br /><br />
                                <a onClick={() => navigate('/passwordrecovery')}>{t("Forgot my password")}</a><br /><br />
                                <label>{t("You don't have an account?")}</label><a onClick={() => navigate('/signup')}> {t("Sign in")}</a>
                            </div>
                        )}
                        {type === "signup" && (
                            <>
                                <label htmlFor="passwordV">{t("Repeat password")}</label>
                                <input className='vc-input' style={inputStyle} id="passwordV" type="password" ref={passwordVRef} /><br />
                                <div className='footer'>
                                    <button>{t("Sign in")}</button><br /><br />
                                    <label>{t("Do you have an account?")}</label><a onClick={() => navigate('/login')}> {t("Login")}</a>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}

const inputStyle = {
    width: "100%"
}