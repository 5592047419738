import React from 'react';
import './styles/LogInfo.css';

type props = {
    hide: () => void
}

const LogInfo = React.forwardRef((props: props, ref: React.Ref<HTMLDivElement>) =>
(
<div ref={ref} className='log-information-item'>
        <div className='log-information-background' onClick={props.hide} />
        <div className='log-information-card'>
            <img className='close-window' src={process.env.PUBLIC_URL + '/img/x-icon.png'} onClick={props.hide}></img>
            <div>Aqui va el escrito</div>
        </div>
        <div className='log-information-card'>
            <img className='close-window' src={process.env.PUBLIC_URL + '/img/x-icon.png'} onClick={props.hide}></img>
            <div>Aqui va el escrito</div>
        </div>
    </div>
));

export default LogInfo;