import './styles/Publish.css'
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { Course, deleteCourse, duplicateCourseOnAPI, postCourse } from './scripts/courses'
import { setCourseStartDate, setCourseBooleanField, setCourseEndDate, setCourseNumericField } from './scripts/fields';
import DatePicker from 'react-datepicker';
import { Timestamp } from 'firebase/firestore';

type props = {
    course: Course | null,
    setCourse: React.Dispatch<React.SetStateAction<Course | null>>
}

function Publish(props: props) {
    const { courseid } = useParams();
    const navigate = useNavigate();
    const { course, setCourse } = props;
    const [price, setPrice] = useState(0);
    const currencySelector = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        setPrice(course?.price || 0);
        currencySelector.current ? currencySelector.current.selectedIndex = course?.currency || 0 : console.log("no ha cargado");
    }, [course])

    const setCoursePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newPrice = parseInt(e.target.value, 10);
        if (!Number(newPrice)) {
            return;
        }
        else setPrice(newPrice);
    }

    const removeCourse = async () => {
        if (window.confirm('¿Estas seguro que deseas eliminar el curso <' + course?.title + '>?')) {
            if (courseid)  await deleteCourse(courseid);
            navigate("/");
        } 
    }

    const duplicateCourse = async () => {
        if (window.confirm('¿Estas seguro que deseas duplicar el curso <' + course?.title + '>?')) {
            if (courseid) await duplicateCourseOnAPI(courseid);
            navigate("/");
        } 
    }

    return (
        <>
            <div className='publish-area'>
                <div className='price-area'>
                    <div>
                        <label>Costo del curso: </label><br></br>
                        <div className='money'>
                        <div className='money-icon'>$</div>
                        <input
                            style={style}
                            className="vc-input reverse-input"
                            type="text"
                            onBlur={() => { setCourseNumericField(courseid || '', 'price', price); if (course) course.price = price }}
                            onChange={setCoursePrice}
                            value={price}></input>
                        </div>
                    </div>
                    <div className="box">
                        <select ref={currencySelector} onChange={(e) => { setCourseNumericField(courseid || "", "currency", e.target.selectedIndex) }}>
                            <option>MXN</option>
                            <option>USD</option>
                        </select>
                    </div>
                </div>
                <div className='start-date-calendar'>
                    <div>
                        <label>Fecha de inicio:</label><br />
                        <DatePicker
                            customInput={<input style={style} className='vc-input' />}
                            showTimeSelect selected={course?.start_date.toDate()}
                            onChange={(date: Date) => { setCourse({...course!, start_date: Timestamp.fromDate(date)}); setCourseStartDate(courseid || '', date) }} />
                    </div>
                    <div style={{ width: "10px", height: "40px", alignItems: "center", display: "flex" }}><label>-</label></div>
                    <div>
                        <label>Fecha de cierre:</label><br />
                        <DatePicker
                            customInput={<input style={style} className='vc-input' />}
                            showTimeSelect selected={course?.end_date.toDate()}
                            onChange={(date: Date) => { setCourse({...course!, end_date: Timestamp.fromDate(date)}); setCourseEndDate(courseid || '', date) }} />
                    </div>
                </div>
                <div className='flex gap-5'>
                <div>
                    <label>Estado:</label>
                        {!course?.enabled ? (
                            <div className='publish-button' onClick={() => { setCourse({...course!, enabled: true}); setCourseBooleanField(courseid || '', 'enabled', true) }}>
                                <img className="publish-icon" src={process.env.PUBLIC_URL + '/img/publish-icon.png'} alt="Publish Icon"></img>
                                <div>Habilitar</div>
                            </div>
                        ) : (
                            <div className='file-button' onClick={() => { setCourse({...course!, enabled: false}); setCourseBooleanField(courseid || '', 'enabled', false) }}>
                                <img className="file-icon" src={process.env.PUBLIC_URL + '/img/publish-icon.png'} alt="File Icon"></img>
                                <div>Deshabilitar</div>
                            </div>
                        )}
                    </div>
                    <div>
                        <label>Publicación:</label>
                        {!course?.published ? (
                            <div className='publish-button' onClick={() => { setCourse({...course!, published: true}); setCourseBooleanField(courseid || '', 'published', true) }}>
                                <img className="publish-icon" src={process.env.PUBLIC_URL + '/img/publish-icon.png'} alt="Publish Icon"></img>
                                <div>Publicar</div>
                            </div>
                        ) : (
                            <div className='file-button' onClick={() => { setCourse({...course!, published: false}); setCourseBooleanField(courseid || '', 'published', false) }}>
                                <img className="file-icon" src={process.env.PUBLIC_URL + '/img/publish-icon.png'} alt="File Icon"></img>
                                <div>Archivar</div>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <label>Duplicar curso:</label>
                    <div className='duplicate-button-course' onClick={duplicateCourse}>
                        <div> Duplicar curso</div>
                    </div>
                </div>
                <div>
                    <label>Eliminar curso:</label>
                    <div className='erase-button-course' onClick={removeCourse}>
                        <div> Eliminar curso</div>
                    </div>
                </div>
            </div>
        </>
    );
}

const style = {
    height: "40px",
    width: "190px"
}
export default Publish;