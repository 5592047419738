import './styles/Professor.css'
import { Professor } from './scripts/professors';
import { User } from './scripts/users';
import { useAuth } from '../../context/AuthProvider';

type props = {
    professor: Professor;
    showProfessor: (professor: Professor) => void,
    removeProfessor: (professor: Professor) => void
}

function ProfessorCard(props: props) {
    let { professor, showProfessor, removeProfessor} = props;
    const {admin} = useAuth();
    return (
        <div className='professor' key={professor.id}>
            <div className='professor-item' onClick={() => showProfessor(professor)}>
                <div className='professor-img-content'>
                    <img src={professor.thumbnail || process.env.PUBLIC_URL + '/img/none-profile-photo.png'}></img>
                </div>
                <div className='professor-title-content'>
                    <div>{professor.name}</div>
                </div>
                <div className='professor-data'>
                    <div>{professor.special}</div>
                </div>
            </div>
            {admin && <img className='delete-professor' src={process.env.PUBLIC_URL + '/img/delete-dark-icon.png'} width="20px" onClick={() => removeProfessor(professor)}></img>}
        </div>
    );
}

export default ProfessorCard;