import axios from "axios";
import { apiUri } from "./endpoints";

export async function getLogs(dateToQuery: string) : Promise<Log[]> {
    let config = {
        headers: {
          logdate: dateToQuery,
        }
      }
    const response =
        await axios.get<PreLog[]>(apiUri + "/logs", config)
    const unformattedLogs = response.data;
    console.log(unformattedLogs);
    const logs = unformattedLogs.map(log => ({
        author: log.author,
        operation: log.operation,
        description: log.description,
        oldValue: log.oldValue,
        newValue: log.newValue,
        date: new Date(log.time._seconds * 1000).toDateString(),
        time: new Date(log.time._seconds * 1000).toTimeString()
    }))
    return logs;
}

export async function getDateRange() : Promise<DateRange> {
    const response = await axios.get<string[]>(apiUri + "/logs/keys");
    return {
        minDate: response.data[0],
        maxDate: response.data[response.data.length - 1]
    };
}

export type Log = {
    date: string,
    time: string,
    author: string,
    operation: string,
    description: string,
    oldValue: string,
    newValue: string
};

type PreLog = {
    time: Timestamp,
    author: string,
    operation: string,
    description: string,
    oldValue: string,
    newValue: string
};

type Timestamp = {
    _seconds: number;
    _nanoseconds: number;
}

export type DateRange = {
    minDate: string;
    maxDate: string;
}