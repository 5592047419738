import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Course } from "./scripts/courses";
import { setCourseNumericField } from "./scripts/fields";
import "./styles/CourseQuick.css";
import { useTranslation } from 'react-i18next';

type props = {
    course: Course | null;
    contentEditable: boolean;
}

function CourseQuick(props: props) {
    const { courseid } = useParams();
    const [time, setTime] = useState(0);
    const { course, contentEditable } = props;
    const { t } = useTranslation();
    useEffect(() => {
        setTime(course?.time || 0);
    }, [course]);
    return ( 
        <div className="left-content">
            <div className='content-box'>
                <div className='title-box'> {t("Content")}</div>
                <div className='text-box-content'>
                    <img src={process.env.PUBLIC_URL + '/img/clock.png'} alt="Clock"></img>
                    {contentEditable
                        ? <input 
                            type="number"
                            style={{width: "40px", marginLeft: "5px"}} 
                            onChange={(e) => setTime(Number.parseInt(e.target.value, 10))} 
                            onBlur={(e) => setCourseNumericField(courseid || "", "time", Number.parseInt(e.target.value, 10))}
                            value={time}/>
                        : <div>{(course ? course.time : "0")}</div>}<div>hrs.</div>
                </div>
                <div className='text-box-content'>
                    <img src={process.env.PUBLIC_URL + '/img/lessons-icon.png'} alt="Book"></img>
                    <div>{(course ? course.levels.length : "0") + t("Lessons")}</div>
                </div>
                <div className='text-box-content'>
                    <img src={process.env.PUBLIC_URL + '/img/teachers-icon.png'} alt="Professors"></img>
                    <div>{(course ? course.professors.length : "0") + t("Professors")}</div>
                </div>
                <div className='text-box-content'>
                    <img src={process.env.PUBLIC_URL + '/img/sub-icon.png'} alt="Students"></img>
                    <div>{(course ? course.studentCount : "0") + t("Registered")}</div>
                </div>
            </div>
        </div>
     );
}

export default CourseQuick;