import React from "react";
import { NavigateFunction } from "react-router-dom";
import ReactSelect from "react-select";
import { useAuth } from "../../context/AuthProvider";
import { useLanguage } from "../../context/LanguageProvider";
import RSOption from "../custom/RSOption";

type CustomProps = {
    searchBar?: boolean,
    logOut: () => Promise<void>,
    navigate: NavigateFunction,
    callLogin?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function UpperBar(props: CustomProps) {
    const { user } = useAuth();
    const [lang, changeLanguage] = useLanguage();

    function handleLanguage(e: {value: string;label: JSX.Element;} | null) {
        if (e) {
          changeLanguage(e.value);
        }
    }

    return (
        <div className='bar'>
            <img className='logo' src={process.env.PUBLIC_URL + '/img/mfm-white.png'} alt="MFM Logo" onClick={() => props.navigate("/")}></img>
            <div style={{"flex": "1 2"}}>
              {props.searchBar && (
                <div className='searchbar'>
                    <input type="text" name="search"></input>
                </div>
              )}
            </div>
            <ReactSelect 
                  styles={customStyles}
                  defaultValue={languages.find(language => language.value === lang) || languages[0]}
                  options={languages}
                  onChange={handleLanguage}/>
            <div className="dropdown" >
            <img className='accountIcon' src={process.env.PUBLIC_URL + '/img/accountIcon.png'} alt="Account Icon"></img>
                <div className="dropdown-content">
                    <p style={logoutStyle} onClick={user ? props.logOut : (() => props.callLogin!(true))}>{user ? "Logout" : "Login"}</p>
                </div>
            </div>
        </div>
    );
}

const languages = [
    {value: "en", label: <RSOption title="English" src={process.env.PUBLIC_URL + "/img/us.svg"}/>},
    {value: "es", label: <RSOption title="Español" src={process.env.PUBLIC_URL + "/img/mx.svg"}/>}
]

const logoutStyle = {
    cursor: "pointer"
}

const customStyles = {
    menu: (provided: any, state: any) => ({
        ...provided,
        width: state.selectProps.width,
        backgroundColor: "#ffffff6b",
      }),

    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'black' : 'black',
      backgroundColor: state.isSelected ? '' : '',
      "&:hover": {
        backgroundColor: "#d8d8d8"
      },
      display: "flex",
      alignItems: "center",
      gap: 5
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 150,
      display: 'flex',
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      
      return { 
        ...provided, 
        opacity, 
        transition, 
        color: "white",
        display: 'flex',
        alignItems: 'center',
        gap: 5
     };
    }
  }