import React, { useEffect, useState } from 'react';
import LogTable from './LogTable';
import { DateRange, getDateRange, getLogs, Log } from './scripts/logs';
import './styles/LogMain.css'
import DatePicker from 'react-datepicker';
import { add, min, sub } from 'date-fns';

type props = {
    showRecord: () => void,
    path?: string
}

function LogMain(props: props) {
    const { showRecord } = props;
    const [logs, setLogs] = useState<Log[]>([]);
    const [done, setDone] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [dateRange, setDateRange] = useState<DateRange>();
    useEffect(() => {
        const downloadLogs = async () => {
            const tempRange = await getDateRange();
            setDateRange(tempRange);
            if (tempRange) {
              console.log(tempRange.maxDate);
              setLogs(await getLogs(tempRange.maxDate));
              setSelectedDate(add(new Date(tempRange.maxDate), { days:1 }))
            }
            setDone(true);
        } 
        if (!done) {
            downloadLogs();
        }
    }, [logs]);
    const loadLogs = async (date: string) => {
      setLogs(await getLogs(date));
    }
    const columns = React.useMemo(
        () => [
          {
            Header: 'Registro de operaciones',
            columns: [
              {
                Header: 'Fecha',
                accessor: 'date',
              },
              {
                Header: 'Hora',
                accessor: 'time',
              },
              {
                Header: 'Autor',
                accessor: 'author',
              },
              {
                Header: 'Tipo',
                accessor: 'operation',
              },
              {
                Header: 'Descripción',
                accessor: 'description',
              },
            ],
          },
        ],
        []
      )
    return (
      <>
        <div style={{display: "flex", justifyContent: "end", width: "100%"}}>
          <div style={style}>
            {selectedDate && <DatePicker 
              customInput={<input style={style} className='vc-input' />}
              selected={selectedDate}
              onChange={(date: Date) => { setSelectedDate(date); loadLogs(sub(date, {days: 1}).toISOString().split('T')[0])}}
              minDate={add( new Date(dateRange?.minDate || ""), { days: 1 } )}
              maxDate={add( new Date(dateRange?.maxDate || ""), { days: 1 } )}
            />}
          </div>
        </div>
        <LogTable 
          columns={columns} 
          data={logs} 
          showRecord={showRecord}/>
      </>
    )
}

const style = {
  height: "40px",
  width: "190px"
}

export default LogMain;